
/* common */

.inline-block{
  display: inline-block;
}
.align-item-availability-center{align-items: center;}
.noWrap{
  white-space: noWrap;
}


.session-availability-container{
  padding-left: 1rem;
}

.per-session-availability-container{
  width: 470px;
  height: 112px;
  background-color: f5f5f5;
  border: 1px solid #cdcdcd;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-weight: 700;
  /* margin: auto; */
}

.week-days{
  background-color: #b1b1b1;
  height:112px;
  width:107px;
  /* width:90px; */
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid #cdcdcd;
}


.time-set-availability-container{
  width:192px;
  height: 32px;
}

.input-time-availability{
  width: 96px;
  height: 32px;
border:1px solid #bbbbbb
}
.availability-add-button{
  width: 144px;
  height: 32px;
  padding-top: 4px;
  margin-bottom: 12px;
  margin-left:2px;

  /* border:1px solid #A862FF !important;
  background-color: transparent !important;
  color:#A862FF !important */

  border: 1px solid #FEB800  !important;
  background-color: #FEB800 !important;
  color:white !important

}

.session-availabilty{
  width: 180px;
  padding-left: .5rem;
  /* border:1px solid #A862FF !important;
  background-color: #f5f5f5 !important;
  color:#A862FF !important */
  border:1px solid #FEB800 !important;
  background-color: #FEB800 !important;
  color:white !important
}


.overrides-availability-container{
border:1px solid #cdcdcd;
background-color: f5f5f5;

}
.overrides-availability-container h1{
  color: white;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #B1b1b1;
  border-top-left-radius:8px;
  border-top-right-radius:8px;
}

